import React from 'react';
import PropTypes from 'prop-types';
import Text from '../../Text';
import Link from '../../Link';
import Image from '../../Image';

const Logo = ({
  logoWrapperStyle,
  logoStyle,
  titleStyle,
  withAchor,
  anchorProps,
  logoSrc,
  title,
  children,
  ...props
}) => (
  <Link {...props} {...logoWrapperStyle} style={{alignItems: 'center'}}>
    {withAchor ? (
      <a {...anchorProps}>
        {logoSrc ? (
          <Image src={logoSrc} alt={title} {...logoStyle}/>
        ) : (
          <>{children}<Text  {...titleStyle} >SADDLE MOUNTAIN <br /> <span style={{
            borderTop: "1px solid #666",
            fontSize: ".7rem",
            margin: "3px 0 0 1px",
            lineHeight: "1rem",
            letterSpacing: ".2rem"}}>GROUP</span></Text></>
        )}
      </a>
    ) : (
      <>
        {logoSrc ? (
          <Image src={logoSrc} alt={title} {...logoStyle} />
        ) : (
          <>{children}<Text {...titleStyle} style={{lineHeight: '1.4rem',letterSpacing: ".1rem"}}><span style={{fontSize: '1.4rem'}}>S</span>ADDLE <span style={{fontSize: '1.4rem'}}>M</span>OUNTAIN <br /> <span style={{
            borderTop: "1px solid #666",
            fontSize: ".8rem",
            margin: "0 0 0 1px",
            paddingTop: '1px',
            lineHeight: "1.05rem",
            display: 'inline-block',
            letterSpacing: ".2rem"}}>GROUP</span></Text></>
        )}
      </>
    )}
  </Link>
);

Logo.propTypes = {
  logoSrc: PropTypes.string,
  title: PropTypes.string.isRequired,
  logoWrapperStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  titleStyle: PropTypes.object,
  withAchor: PropTypes.bool,
  anchorProps: PropTypes.object,
};

Logo.defaultProps = {
  logoWrapperStyle: {
    display: 'flex',
    mr: '1rem',
    'a:hover,a:focus': {
      textDecoration: 'none',
    },
  },
  titleStyle: {
    display: 'inline-block',
    fontSize: '1rem',
    lineHeight: 'inherit',
    whiteSpace: 'nowrap',
    color: '#666', 
    fontFamily: "Lato, sans-serif",
    mb: 0,
    ml: '1rem'
  },
};
export default Logo;
